import Amplify, { API } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import React, { useState, useEffect } from "react";
import awsconfig from "./aws-exports";
import "./App.css";
import Dashboard from "./template/Dashboard";

// Configure Amplify
Amplify.configure(awsconfig);
API.configure(awsconfig);

function App() {
  return <Dashboard />;
}

export default withAuthenticator(App, {
  // Render a sign out button once logged in
  includeGreetings: false
});
