import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import MaterialTable from "material-table";
import Title from "../template/Title";

const FundsTable = () => {
  const [funds, setFunds] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        // const apiData = await API.graphql(
        //   graphqlOperation(queries.getFunds, { date: new Date().toISOString() })
        // );

        // The authMode should only be required on multi-auth types:  https://aws-amplify.github.io/docs/js/api#aws-appsync-multi-auth
        const apiData = await API.graphql({
          query: queries.getFunds,
          variables: { date: new Date().toISOString() },
          authMode: "AMAZON_COGNITO_USER_POOLS"
        });

        setFunds(apiData.data.getFunds);
      } catch (e) {
        console.log("error fetching funds", e);
      }
    };

    getData();
  }, []);

  return (
    <React.Fragment>
      <Title>Funds</Title>
      <MaterialTable
        columns={[
          { title: "Id", field: "id", type: "numeric" },
          { title: "Fund", field: "name" },
          { title: "Category", field: "category" },
          {
            title: "Value",
            field: "value",
            type: "numeric"
          },
          {
            title: "Change",
            field: "valueChange",
            type: "numeric"
          },
          { title: "Change %", field: "percentChange" },
          { title: "Date", field: "dataTimestamp" }
        ]}
        data={funds}
        options={{ showTitle: false }}
      />
    </React.Fragment>
  );
};

export default FundsTable;
