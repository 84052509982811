/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFunds = `query GetFunds($date: String!) {
  getFunds(date: $date) {
    id
    name
    category
    value
    valueChange
    percentChange
    dataTimestamp
  }
}
`;
