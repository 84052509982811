/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c693834c-c610-4df6-a041-bf70a00c89bf",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8ObUHrYOp",
    "aws_user_pools_web_client_id": "35jcvklgucr6o0jnebtgigil77",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://r6jdafygmfahtd4rdmj27s6dua.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
